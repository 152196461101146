import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import testimonialImage from "../Assets/Images/testimonial.jpg";
// import scheduledImage from "../Assets/Images/walter-kim.png";
import quotationImage from "../Assets/Images/quotation.svg";
import sampleDashboardTeamImg from "../Assets/Images/sampleDashboardTeamImg.png"
import sampleDashboardIndividualImg from '../Assets/Images/sampleDashboardIndividualImg.png'
import sampleDashboardTeamImgMobile from '../Assets/Images/sample-dash-team-mobile.png'
import sampleDashboardIndividualImgMobile from '../Assets/Images/sample-dash-indi-mobile.png'
import InviteInfo from "../Components/InviteInfo";
import Testimonial from "../Components/Testimonial";
import Resources from "../Components/Resources";
import DonutChart from "../Components/DonutChart";
import SendInvite from "../Components/SendInvite";
// import DonutChartAccordion from "../Components/DonutChartAccordion";
import UpArrow from '../Assets/Images/upArrow.png'
import { Button, Accordion, AccordionSummary, AccordionDetails, Box, Typography, LinearProgress, CircularProgress, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Remove } from "@mui/icons-material";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import "../App.scss";
import ClickableCircularProgressBars from "../Components/ClickableCircularProgressBars";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const Results = (props) => {
  let navigate                    = useNavigate();
  const { id }                    = useParams();
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember]   = useState(false);
  const [apiData, setApiData]     = useState();
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [sections, setSections]   = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [currentView, setCurrentView] = useState(0);
  const [activeScoreSection, setActiveScoreSection] = useState()
  const [chartsData, setChartsData] = useState();

	
  useEffect(() => {
    // document.body.style.backgroundColor = "#435B63";
    fetchResults();
  }, []);

  useEffect(() => {
    // basic check for API data reliability 
    if(apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        { title: "The community around us is aware of our mission.", 
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1} },
        { title: "How many engagement opportunities did you host in the past year open to the larger community?", 
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2} },
        { title: "Throughout last year, how many community members participated in these engagement opportunities?", 
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3} },
        { title: "Over time, our church's engagement with our community has been...", 
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4} },
        { title: "Our community frequently utilizes our church's building facilities and programs.", 
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5} },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };
    
    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  useEffect(()=>{
    console.log('isMember',isMember)
  },[isMember])

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          
          setApiData(data);
          setName(data.name);
          setScore(data.score);
          setSections(data.sections);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setIsMember(data.isMember);
          setActiveScoreSection(data.sections[0]?.section)
          // console.log(data);

          setIsLoading(false);

        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);
  
  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);

  const showTitleText = () => {
    if (score < 75) {
      return <h1>{name}, it seems like you may have your work cut out for you.</h1>
    } else if (score >= 75 && score < 90) {
      return <h1>{name}, it seems like you are almost ready for launch.</h1>
    } else if (score >= 90) {
      return <h1>{name}, you are ready to build!</h1>
    }
  };

  const showScoreText = () => {
    // const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    // const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);
    
    // if(allScoresSame) {
    //   return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>We are glad to see that you maintain a balanced focus across all fronts of your strategy planning and execution.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips to take your strategy impact to the next level:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // } else {
    //     return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>Keep up the good work on your <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to your <span style={{fontWeight: "700"}}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // }

    return (<div className="result-text">
			<p>A good idea is great, but your team or key stakeholders may not always see its value or align with pursuing it now. Even if you're past this stage, there may be other barriers.</p>
			<p>We have a plan for you.</p>
		</div>);

  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    // else if (props?.showInvite === false) return null;
    else return <InviteInfo />
  };

  const showNextSteps = () => {
    if (isMember) return null;
    // else if (props?.showNextSteps === false) return null;
    else return (
      <div className="next-steps-container">
        <h3>Here are your next steps:</h3>
        <ol className="next-steps-list">
          <li>Invite your leadership team to take this same test that you just took</li>
          <li>See their individual responses and scores on one dashboard*</li>
        </ol>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>Invite My Team</Button>
          </Link>
        </div>

        <div className="disclaimer">
          <p>*Scroll down to see what your dashboard could look like.</p>
        </div>

      </div>
    );
  }

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          <h3>Please get in touch with your leader for the next step.</h3>
        </div>
      );
    } else {
      return null;
    }
    // if(toggleIndividual == 1){
    //   return (
    //     <div className="team-container">
    //       <h3>Thank you for taking this assessment!</h3>
    //       Please get in touch with your leader for the next step.
    //       <br />
    //       <br />
    //     </div>
    //   );
    // }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <Testimonial />;
    } else {
      // return null;
      return <Testimonial />;
    }
  };

  const showResources = () => {
    return <Resources />
  }

  const sendInvite = () => {
    return <SendInvite />
  }

  const getLeftMargin = () => {
    if (score == 100) return { left: "99%" };
    if (windowWidth < 651 && score > 98) {
      return { left: 98.5 + "%" };
    } else if (windowWidth < 651 && score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            {/* <img src={scheduledImage} /> */}
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
				  <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Invite your team members</h1>
            <p>Get your team to take this test and see if they think your church is guest ready.</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>Invite My Team</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }  

  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#796BE1' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result" style={{ position: "relative" }}>
              <div className="progress-opacity-filter" style={{ width: "100%" }}>
                <div className="transparent" style={{ width: `${Math.round(score)}%`, height: windowWidth < 651 ? "11px" : "26px", backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
                <div className="opaque" style={{ width: `${(100 - Math.round(score))}%`, height: windowWidth < 651 ? "11px" : "26px", backgroundColor: "rgba(255, 255, 255, 0.75)" }}></div>
              </div>
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
                <div className="score-arrow" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
              </div>
              <div className="pervalue">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-75-percent" style={{ minWidth: '75%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-90-percent" style={{ minWidth: '15%' }}></h4>
                <h4 className="progress-100-percent" style={{ minWidth: '10%' }}></h4>
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-75-percent" style={{ minWidth: '75%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-90-percent" style={{ minWidth: '15%' }}></h4>
                <h4 className="progress-100-percent" style={{ minWidth: '10%' }}></h4>
                
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: "75%", fontWeight: score < 75 ? 700 : 400 }}>Needs Work</p>
                {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                <p className="some-aligned" style={{ width: "15%", fontWeight: score >= 75 && score < 90 ? 700 : 400 }}>Almost<br/>There</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                <p className="high-aligned" style={{ width: windowWidth <= 550 ? "11.5%" : "10%", fontWeight: score >= 90 ? 700 : 400 }}>Ready to Build</p>
              </div>

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* {showScoreText()} */}

          <div className="score-text">
            <p>A good idea is great, but your team or key stakeholders may not always see its value or align with pursuing it now. Even if you're past this stage, there may be other barriers.<br/>We have a plan for you.</p>
          </div>

          {windowWidth > 830 && <h3 className="pre-score-header">Click on the circles below to unlock specific recommendations.</h3>}

          <ClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} />

          
          {/* {!isMember && <div className="invite-cta-btn">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE MY TEAM</Button>
            </Link>
          </div>} */}

         {/* <div className="charts-accordions-container">
            {chartsData?.map((chart, idx) => {
              return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <p>{chart?.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} />
                </AccordionDetails>
              </Accordion>);
              })
            }
          </div> */}

        </div>
      </div>
      
      {/* {showLegacyInvite()} */}

      {showNextSteps()}
      
      {
        !isMember ? 
        <div className="sample-dashboard-container">
          <div className="sample-dashboard-text">
            <div className="right-line"></div>
            <h1>Sample Dashboard</h1>
            <div className="left-line"></div>
          </div>
          <div className="individual-group">
            <button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
            <button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
          </div>
          <div className="toggle-individual-group">
            <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
            <Switch
              checked={!toggleIndividual}
              onChange={toggleIndividualChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
          </div>

          <div className="dashboard-report-img-container">
            {
              windowWidth > 550 ? <>
                <img src={currentView == 0 ? sampleDashboardTeamImg : sampleDashboardIndividualImg} alt="sample team dashboard" />
              </>
                :
                <>
                  <img src={currentView == 0 ? sampleDashboardTeamImgMobile : sampleDashboardIndividualImgMobile} alt="sample individual dashboard" />
                </>
            }
          </div>
        </div> : <></>
      }

      {/* {showInvite()} */}

      {showTeamMember()}


      {showInvite()}

      {/* {sendInvite()} */}

      {showTestimonial()}

      {/* {showResources()} */}
      

      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};

export default Results;

