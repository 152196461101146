import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/Justin-Testimonial.png';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../Assets/Images/leftQuote.png';
import bottomQ from '../Assets/Images/rightQuote.png';


const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">
          <div className="testi">
            <div className="quotation-mark"><img src={topQ} alt="open quote" /></div>
            <p>We spent years facing frustrations with our software development process until we started working with Hopeware. They helped us establish a clear product roadmap, which allowed us to move more quickly.</p>
            <div className="quotation-mark bottom">
              <div className="quotation-block">
                <img src={bottomQ} alt="close quote" style={{transform:'rotate(180deg)'}} />
              </div>
            </div>
          </div>
          
          <div>
            <div className="avatar">
              <img src={testimonialImage} alt="pastor" width={101} height={101} />
            </div>
          </div>
          <div style={{ display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center' }}>          
            <div className="designation">
            Justin Trapp<p className="designation-inner">Founder, Front Door</p> 
            </div>
          </div>
          
          {/* <div className="cta-btn">
            <Link to={"/invite-team/" + id} target="_blank">
            <Button>DISCOVER YOUR PRIORITY</Button>
            </Link>
          </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;
