export const sectionsText = {
  
  product: {
    recommendation: {
      header: ["What we recommend you do:"],
      text: [
        "1. Survey your product development team to understand what aspects of your product resonate with them and areas they want to change or improve.",
        "2. Interview 5-10 customers to ascertain and understand what they like most and least about your product. Document common themes.",
      ],
    },
    service: {
      header: ["How Hopeware can serve you:"],
      text: [
        "We can assess your roadmap, identify gaps, areas for enhancement, and growth opportunities, and share a clearly defined plan for your team.",
      ]
    }
  },

  platform: {
    recommendation: {
      header: ["What we recommend you do:"],
      text: [
        "1. Survey your teams to uncover potential barriers in the product's development, launch, and ongoing support.",
        "2. Document your current processes. Look for bottlenecks, redundancies, and dependencies to eliminate and streamline your processes.",
      ],
    },
    service: {
      header: ["How Hopeware can serve you:"],
      text: [
        "We can perform a thorough product development assessment, analyze your workflows, processes, tools, and product roadmap, prioritize critical and minor issues, and provide clear recommendations to increase and optimize efficiencies.",
      ]
    }
  },

  people: {
    recommendation: {
      header: ["What we recommend you do:"],
      text: [
        "Assess your team by conducting comprehensive, 360-degree reviews to thoroughly understand strengths and spot areas for development for each team member.",
      ],
    },
    service: {
      header: ["How Hopeware can serve you:"],
      text: [
        "We can counsel and coach your team leads to overcome barriers and be better prepared as you design, build, launch, and scale your product.",
      ]
    }
  },

  resources: {
    recommendation: {
      header: ["What we recommend you do:"],
      text: [
        "Forecast costs and recalibrate your budget based on the latest timelines and requirements to continually improve your plan.",
      ],
    },
    service: {
      header: ["How Hopeware can serve you:"],
      text: [
        "We can conduct a thorough review of your financial plans, proformas, and P&L (profit and loss) and model various revenue scenarios based on pricing structures.",
      ]
    }
  },

  launch: {
    recommendation: {
      header: ["What we recommend you do:"],
      text: [
        "1. Keep a close eye on your target audience using surveys and continue interacting with them closely to keep a pulse on their latest behaviors, desires, and needs.",
        "2. Continually review buyer and user personas to capture evolving behaviors and trends.",
      ],
    },
    service: {
      header: ["How Hopeware can serve you:"],
      text: [
        "1. We can fix our eyes on your competition to understand their launch programs and see how you can add unique value for your target audience.",
        "2. We can also perform in-depth user research, analyze your customer and user journeys, and study your target customer segments to inform your messaging, product positioning, and go-to-market strategy."
      ]
    }
  },

  // TODO: update sameScores text with 40p feedback
  sameScores: {
    recommendation: {
      header: ["What we recommend you do:"],
      text: [
        "Survey your product development team to understand what aspects of your product resonate with them and areas they want to change or improve.",
        "Interview 5-10 customers to ascertain and understand what they like most and least about your product. Document common themes.",
      ],
    },
    service: {
      header: ["How Hopeware can serve you:"],
      text: [
        "We can assess your roadmap, identify gaps, areas for enhancement, and growth opportunities, and share a clearly defined plan for your team.",
      ]
    }
  },

};

